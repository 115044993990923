import { Box } from "grommet";
import { DotPulse } from "./dotPulse/DotPulse";
import { HorizontalSeparator } from "./HorizontalSeparator";
import React from "react";
import styled from "styled-components";
import useMediaQuery from "../hooks/ueMediaQuery";

const PlaceholderTeamWeekCardContainer = styled.div`
  box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
  background-color: white;
  padding: 15px;
  margin: 10px;
  width: 720px;
  height: 260px;
`;

export const TeamColumnPlaceholder = ({ single }: { single?: boolean }) => {
  const isDesktop = useMediaQuery("(min-width:650px)");

  const SingleTeamWeekCardPlaceholder = () => (
    <PlaceholderTeamWeekCardContainer
      style={{ maxWidth: isDesktop ? "unset" : "90%" }}
    >
      <Box pad="50px" justify="center" alignContent="center">
        <DotPulse />
      </Box>
    </PlaceholderTeamWeekCardContainer>
  );

  if (single) {
    return (
      <Box pad={{ top: "medium" }}>
        <SingleTeamWeekCardPlaceholder />
      </Box>
    );
  }
  return (
    <Box pad={{ top: "medium" }}>
      <SingleTeamWeekCardPlaceholder />
      <HorizontalSeparator />
      <SingleTeamWeekCardPlaceholder />
      <HorizontalSeparator />
      <SingleTeamWeekCardPlaceholder />
      <HorizontalSeparator />
    </Box>
  );
};
