import React, { useEffect, useState } from "react";
import { Box, Card, Text } from "grommet";
import styled from "styled-components";
import { colors } from "../assets/theme";
import { AppUser, AppUserViewModel } from "../types";
import { LoadingScreen } from "../components/LoadingScreen";
import { useParams } from "react-router-dom";
import { OccurrenceType } from "../enums";
import { capitalizeFirstLetter, dateFormatter } from "../utils";
import {
  SummaryLabel,
  SummarySubtitle,
} from "../components/summaryCards/SummaryCards";
import { AppUserBadge } from "../components/AppUserBadge";
import { useGetTeam } from "../hooks/useGetTeam";
import { blinkAnimation } from "../animations/blinkAnimation";
import { format } from "date-fns";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: ${() => colors.plStone02};
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 30px;
`;

const Subtitle = styled.span`
  font-weight: bold;
  font-size: 24px;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  background: ${() => colors.plWhite};
  width: 100vw;
  height: 100vh;
  padding: 12px;
`;

const PageContent = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;
  align-self: center;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

// Create a styled component with the blinking animation
const BlinkingText = styled.span`
  animation: ${blinkAnimation} 2s infinite; /* 1s is the duration of one blinking cycle, 'infinite' makes it loop */
  font-size: 20px;
  position: absolute;
  bottom: 40px;
  right: 40px;
`;

export const OfficeToday = () => {
  const [todayInOfficeUsers, setTodayInOfficeUsers] = useState<{
    londonbrunel: (AppUserViewModel & { officeToday: string })[];
    singapore: (AppUserViewModel & { officeToday: string })[];
    newyork: (AppUserViewModel & { officeToday: string })[];
  }>({
    londonbrunel: [] as unknown as (AppUserViewModel & {
      officeToday: string;
    })[],
    newyork: [] as unknown as (AppUserViewModel & { officeToday: string })[],
    singapore: [] as unknown as (AppUserViewModel & { officeToday: string })[],
  });

  const { id: teamId } = useParams();

  const [activePageIndex, setActivePageIndex] = useState(0);
  const pages = [
    OccurrenceType.brunel,
    OccurrenceType.newyork,
    OccurrenceType.singapore,
  ];

  useEffect(() => {
    // Function to rotate between pages every 5 seconds
    const rotatePages = () => {
      setActivePageIndex((prevIndex) => (prevIndex + 1) % pages.length);
      if (teamId) {
        refetchTeam(teamId);
      }
    };

    // Start the rotation with setInterval
    const interval = setInterval(rotatePages, 5000); // 5000 milliseconds = 5 seconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  const { team, refetchTeam } = useGetTeam(teamId);

  useEffect(() => {
    const isTodaysOccurrence = (occurrenceDate: string) => {
      return (
        dateFormatter(occurrenceDate) ===
        dateFormatter(new Date().toISOString())
      );
    };

    const hasOffice = (occurrenceType: OccurrenceType) =>
      occurrenceType === OccurrenceType.brunel ||
      occurrenceType === OccurrenceType.londonbrunel.replace(/\s/g, "") ||
      occurrenceType === OccurrenceType.singapore ||
      occurrenceType === OccurrenceType.newyork.replace(/\s/g, "");

    if (team.id) {
      const usersInOffice = team?.appUsers
        .flatMap((user) =>
          user.occurrences
            .filter(
              (occurrence) =>
                isTodaysOccurrence(occurrence.occurrence_date) &&
                hasOffice(occurrence.occurrenceType)
            )
            .flatMap((occurrence) => ({
              ...user,
              officeToday: occurrence.occurrenceType,
            }))
        )
        .sort((a: AppUser, b: AppUser) =>
          a.name < b.name ? -1 : a.name > b.name ? 1 : 0
        );

      const londonbrunel = usersInOffice?.filter(
        (user) =>
          (user.officeToday as string) === "londonbrunel" ||
          (user.officeToday as string) === "brunel"
      );

      const singapore = usersInOffice?.filter(
        (user) => (user.officeToday as string) === "singapore"
      );

      const newyork = usersInOffice?.filter(
        (user) => (user.officeToday as string) === "newyork"
      );

      setTodayInOfficeUsers({
        londonbrunel: londonbrunel || [],
        newyork: newyork || [],
        singapore: singapore || [],
      });
    }
  }, [team]);

  const getOfficePeople = () =>
    Object.entries(todayInOfficeUsers).map((office, index) => {
      const [officeName, officeUsers] = office;

      return (
        index === activePageIndex && (
          <Box key={officeName} style={{ flex: "1" }}>
            <SummaryLabel>
              <SummarySubtitle>
                {capitalizeFirstLetter(
                  OccurrenceType[officeName as keyof typeof OccurrenceType]
                )}
              </SummarySubtitle>
            </SummaryLabel>
            <Box style={{ flexWrap: "wrap" }}>
              {officeUsers.length > 0 ? (
                officeUsers.map((user) => (
                  <Box
                    key={user.id}
                    margin={{ bottom: "small", left: "medium" }}
                    alignSelf="start"
                  >
                    <AppUserBadge
                      viewMode
                      dontTruncate
                      appUser={user as AppUserViewModel}
                      appUserId={user.id}
                    />
                  </Box>
                ))
              ) : (
                <Box
                  alignContent="center"
                  pad={"10px 12px"}
                  style={{ alignItems: "center" }}
                  margin={{ vertical: "100px" }}
                >
                  <Text>
                    There is nobody in the{" "}
                    {capitalizeFirstLetter(
                      OccurrenceType[officeName as keyof typeof OccurrenceType]
                    )}{" "}
                    office today.
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        )
      );
    });

  if (!team) {
    return <LoadingScreen />;
  }

  return (
    <Container>
      <PageContent>
        <Box direction="column" margin="medium">
          <StyledCard round={false}>
            <Box
              direction="row"
              justify="start"
              align="center"
              background={colors.plWhite}
              pad="0 20px"
            >
              <SummaryLabel>
                <Title>
                  Office Today - {format(new Date(), "EEEE do MMMM yyyy")}
                </Title>
                <Subtitle>{team.name}</Subtitle>
              </SummaryLabel>
            </Box>
            <Box
              direction="column"
              align="center"
              alignSelf="start"
              justify="start"
              width="100%"
              pad={{ vertical: "small", horizontal: "medium" }}
              wrap
              style={{ maxHeight: "100vh" }}
              alignContent="center"
            >
              <Box alignContent="left" width="100%">
                {getOfficePeople()}
              </Box>
            </Box>
            <BlinkingText>
              Office {activePageIndex + 1}/
              {Object.entries(todayInOfficeUsers).length}
            </BlinkingText>
          </StyledCard>
        </Box>
      </PageContent>
    </Container>
  );
};
