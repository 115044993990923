import { AppUserSettings } from "../../types";
import { NotificationsSettingsOptions } from "../../enums";
import { Box } from "grommet";
import { Toggle } from "../toggle/Toggle";
import React from "react";
import { SettingItem } from "./shared/SettingItem";

export const NotificationsSettings = ({
  settings,
  onUpdate,
  isLoading,
}: {
  settings: AppUserSettings;
  onUpdate: (
    payload: Pick<AppUserSettings, NotificationsSettingsOptions>
  ) => void;
  isLoading: boolean;
}) => {
  const onChange = async (
    notificationType: NotificationsSettingsOptions,
    value: boolean
  ) => {
    const settingsCopy = settings;

    settingsCopy[notificationType] = value;

    await onUpdate(settingsCopy);
  };

  return (
    <>
      <SettingItem>
        <Box direction="column">
          <Box>Daily Report</Box>
          <Box style={{ fontSize: 10, lineHeight: "normal" }}>
            (sent Monday to Friday)
          </Box>
        </Box>
        <Toggle
          disabled={isLoading}
          id={"dailyNotification"}
          name={"dailyNotification"}
          small
          onChange={(e: boolean) =>
            onChange(NotificationsSettingsOptions.dailyNotification, e)
          }
          checked={settings.dailyNotification}
        />
      </SettingItem>
      <SettingItem>
        <Box direction="column">
          <Box>Weekly Report</Box>
          <Box style={{ fontSize: 10, lineHeight: "normal" }}>
            (summary of the whole week sent on Monday)
          </Box>
        </Box>
        <Toggle
          disabled={isLoading}
          id={"weeklyNotification"}
          name={"weeklyNotification"}
          small
          onChange={(e: boolean) =>
            onChange(NotificationsSettingsOptions.weeklyNotification, e)
          }
          checked={settings.weeklyNotification}
        />
      </SettingItem>
      {settings.dailyNotification || settings.weeklyNotification ? (
        <SettingItem>
          <Box>My team only</Box>
          <Toggle
            disabled={isLoading}
            id={"onlyOwnTeam"}
            name={"onlyOwnTeam"}
            small
            onChange={(e: boolean) =>
              onChange(NotificationsSettingsOptions.onlyOwnTeam, e)
            }
            checked={settings.onlyOwnTeam}
          />
        </SettingItem>
      ) : null}
    </>
  );
};
