import { colors } from "../../assets/theme";
import { FormClose } from "grommet-icons";
import { Box } from "grommet";
import React from "react";
import styled, { css } from "styled-components";

const topRightCss = () => css`
  position: absolute;
  width: 20px;
  right: 0;
`;
const StyledBox = styled(Box)<{ topRight?: boolean }>`
  ${({ topRight }) => (topRight ? topRightCss : null)}
`;

export const MobileCloseModalButton = ({
  onClick,
  topRight,
}: {
  onClick: () => void;
  topRight?: boolean;
}) => (
  <StyledBox
    topRight={topRight}
    onClick={onClick}
    width="100%"
    pad={{ vertical: topRight ? "unset" : "20px" }}
    align="center"
    margin={topRight ? "20px" : "unset"}
    background={colors.plWhite}
    style={{
      top: 0,
      cursor: "pointer",
      color: colors.plDarkGrey,
      fontSize: "25px",
    }}
  >
    <FormClose
      style={{ background: colors.plPurple, borderRadius: "12px" }}
      color={colors.plWhite}
      size={"medium"}
    />
  </StyledBox>
);
