import React, { useEffect, useMemo, useState } from "react";
import { Box, DateInput, Notification } from "grommet";
import styled from "styled-components";
import { colors } from "../assets/theme";
import { TeamCard } from "../components/TeamCard";
import { AvatarHeader } from "../components/AvatarHeader";
import {
  AppUserViewModel,
  DateInterval,
  Day,
  Occurrence,
  OccurrenceViewModel,
  Team,
  TeamViewModel,
  UpdateSettingsPayload,
} from "../types";
import OccurrenceService from "../services/occurrenceService";
import {
  createDatesInterval,
  dateFormatter,
  generateDays,
  getAppUsersWithDays,
  sortWithUsersEntityFirst,
} from "../utils";
import { HorizontalSeparator } from "../components/HorizontalSeparator";
import { VerticalSeparator } from "../components/VerticalSeparator";
import { PageHeader } from "../components/PageHeader";
import useMediaQuery from "../hooks/ueMediaQuery";
import { EventModal } from "../components/EventModal";
import { LoadingScreen } from "../components/LoadingScreen";
import { DefaultView, OccurrenceType } from "../enums";
import { IconLegend } from "../components/IconLegend";
import AppUserSettingsService from "../services/appUserSettingsService";
import { Info, Search } from "grommet-icons";
import { TabSwitcher } from "../components/TabSwitcher";
import { add, endOfWeek, startOfWeek } from "date-fns";
import { OfficeTodaySummaryCard } from "../components/summaryCards/OfficeTodaySummaryCard";
import { UpcomingEventsSummaryCard } from "../components/summaryCards/UpcomingEventsSummaryCard";
import { PinnedUsersTeamCard } from "../components/PinnedUsersTeamCard";
import { TeamColumnPlaceholder } from "../components/TeamColumnPlaceholder";
import { UpcomingEventsPlaceholder } from "../components/UpcomingEventsPlaceholder";
import { useFetchDepartmentUsers } from "../hooks/useFetchDepartmentUsers";
import { useGetAppUser } from "../hooks/useGetAppUser";
import { useGetDepartment } from "../hooks/useGetDepartment";
import { displayValue } from "react-query/types/devtools/utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1350px;
  background-color: ${() => colors.plStone02};
  z-index: 1;
  min-height: 100vh;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const DashboardOptions = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background-color: #ffffff;
  border-radius: 5px;
  box-sizing: border-box;
`;

const PageContent = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;
  align-self: center;
  width: 100%;
  justify-content: center;
  margin-bottom: 80px;
`;

const TeamsColumn = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledTextInput = styled.input`
  position: relative;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  padding: 10px 5px 10px 20px;
  border: ${() => `1px solid ${colors.plStone1}`};
`;

export const Dashboard = () => {
  const isMinWidth1000 = useMediaQuery("(min-width:1000px)");
  const isDesktop = useMediaQuery("(min-width:650px)");

  const occurrenceService = useMemo(() => new OccurrenceService(), []);
  const appUserSettingsService = new AppUserSettingsService();

  const { appUser, refetchUser } = useGetAppUser();
  const { allUsers } = useFetchDepartmentUsers();
  const { department } = useGetDepartment(appUser?.departmentId);

  const [updatingTeamId, setUpdatingTeamId] = useState<string>("");

  const [isLoadingAppUser, setIsLoadingAppUser] = useState<boolean>(false);
  const [isFetchingTeams, setIsFetchingTeams] = useState<boolean>(false);

  const [isLoadingPinnedUsersData, setIsLoadingPinnedUsersData] =
    useState<boolean>(false);
  const [teams, setTeams] = useState<TeamViewModel[]>([]);

  const [upcomingOccurrences, setUpcomingOccurrences] = useState<Day[]>([]);

  const [todayInOfficeUsers, setTodayInOfficeUsers] = useState<{
    londonbrunel: (AppUserViewModel & { officeToday: string })[];
    singapore: (AppUserViewModel & { officeToday: string })[];
    newyork: (AppUserViewModel & { officeToday: string })[];
  }>({
    londonbrunel: [] as unknown as (AppUserViewModel & {
      officeToday: string;
    })[],
    newyork: [] as unknown as (AppUserViewModel & { officeToday: string })[],
    singapore: [] as unknown as (AppUserViewModel & { officeToday: string })[],
  });

  const [defaultDatesInterval, setDefaultDatesInterval] = useState<
    DateInterval[]
  >([]);

  const [isMutatingPinnedUsers, setIsMutatingPinnedUsers] =
    useState<boolean>(false);

  const [targetUser, setTargetUser] = useState<AppUserViewModel>();
  const [selectedDate, setSelectedDate] = useState<string | string[]>("");
  const [selectedDateOccurrence, setSelectedDateOccurrence] = useState<
    OccurrenceViewModel[]
  >([]);
  const [showToast, setShowToast] = useState<{
    show: boolean;
    message: string;
  }>();

  const [isPinningUserId, setIsPinningUserId] = useState<string>();
  const [pinnedUsers, setPinnedUsers] = useState<AppUserViewModel[]>([]);
  const [pinnedUsersTeam, setPinnedUsersTeam] = useState<TeamViewModel>({
    id: `pinned`,
    name: "Pinned",
    appUsers: [],
    interval: {
      fromDate: defaultDatesInterval[0]?.date,
      toDate: defaultDatesInterval[defaultDatesInterval.length - 1]?.date,
    },
  });

  const [defaultView, setDefaultView] =
    useState<{ label: DefaultView; isActive: boolean }[]>();

  const [filterValue, setFilterValue] = useState<string>("");

  const [isLoadingUpcomingOccurrences, setIsLoadingUpcomingOccurrences] =
    useState<boolean>(false);

  const [isChangingTimeSpan, setIsChangingTimeSpan] = useState<boolean>(false);

  // state to manage calendar popup
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

  const updateUserSettings = async (data: Partial<UpdateSettingsPayload>) => {
    if (appUser) {
      setIsMutatingPinnedUsers(true);
      const newSettings = { ...appUser.appUserSettings, ...data };
      appUserSettingsService
        .updateAppUserSettings(newSettings)
        .then(async () => {
          refetchUser();

          setIsPinningUserId("");
          setShowToast({ show: true, message: "Settings saved." });
        })
        .catch((error) => {
          setIsPinningUserId("");
          setShowToast({
            show: true,
            message: "There was a problem: " + error,
          });
        });
      setIsMutatingPinnedUsers(false);
    }
  };

  const getPinnedUsersTeam = async (pinnedUsersTeam: TeamViewModel) => {
    if (!appUser) return;

    setIsLoadingPinnedUsersData(true);

    const pinnedUsers = allUsers.filter((user) =>
      appUser.appUserSettings.pinnedUsers.includes(user.id)
    );

    setPinnedUsers(pinnedUsers);

    if (appUser.appUserSettings.pinnedUsers.length > 0) {
      const occurrences = (
        await occurrenceService.getOccurrencesByUserIDs({
          userIds: appUser.appUserSettings.pinnedUsers,
          from_date: pinnedUsersTeam.interval.fromDate,
          to_date: pinnedUsersTeam.interval.toDate,
        })
      ).flat();

      const activeDefaultView = defaultView?.find(
        (defaultView) => defaultView.isActive
      );
      const days = activeDefaultView?.label === DefaultView.week ? 7 : 14;

      const usersWithDays = getAppUsersWithDays(
        appUser?.id,
        pinnedUsers,
        createDatesInterval(days, pinnedUsersTeam.interval.fromDate),
        occurrences
      );

      setPinnedUsersTeam({
        ...pinnedUsersTeam,
        appUsers: sortWithUsersEntityFirst(appUser.id, usersWithDays),
      });
    } else {
      setPinnedUsersTeam({ ...pinnedUsersTeam, appUsers: [] });
    }

    setIsLoadingPinnedUsersData(false);
  };

  const buildTeamsWithOccurrences = (
    teams: TeamViewModel[],
    occurrences: Occurrence[],
    datesInterval?: DateInterval[]
  ) => {
    const activeDefaultView = defaultView?.find(
      (defaultView) => defaultView.isActive
    );

    return teams.map((team) => {
      if (team.appUsers.length > 0 && appUser?.id) {
        let interval =
          datesInterval ||
          createDatesInterval(
            activeDefaultView?.label === DefaultView.week ? 7 : 14,
            team.interval.fromDate
          );

        const usersWithDays = getAppUsersWithDays(
          appUser?.id,
          team.appUsers,
          interval,
          occurrences
        );

        return {
          ...team,
          appUsers: sortWithUsersEntityFirst(appUser.id, usersWithDays),
        };
      }

      return team;
    });
  };

  const fetchOccurrencesForTeams = async (teams: TeamViewModel[]) => {
    if (appUser) {
      const today = new Date().setHours(0, 0, 0, 0);

      // fetch occurrences for each teams' own dates interval
      const occurrencesData = teams.map(async (team: TeamViewModel) => {
        return await occurrenceService.getOccurrencesByTeamId({
          teamId: team.id,
          from_date: team.interval.fromDate,
          to_date: team.interval.toDate,
        });
      });

      // fetch occurrences for each team for today
      const todaysOccurrencesData = teams.map(async (team: TeamViewModel) => {
        return await occurrenceService.getOccurrencesByTeamId({
          teamId: team.id,
          from_date: today,
          to_date: today,
        });
      });

      const occurrences = (await Promise.all(occurrencesData)).flat();
      const todaysOccurrences = (
        await Promise.all(todaysOccurrencesData)
      ).flat();

      const activeDefaultView = defaultView?.find(
        (defaultView) => defaultView.isActive
      );
      const activeUserTeam = teams.find((team) => team.id === appUser.teamId);

      // set upcoming events for the right part of the dashboard
      const upcomingOccurrences =
        await occurrenceService.getOccurrencesByTeamId({
          teamId: appUser.teamId,
          from_date: defaultDatesInterval[0]?.date,
          to_date: defaultDatesInterval[defaultDatesInterval.length - 1]?.date,
        });

      if (activeUserTeam) {
        getUpcomingOccurrences(
          getAppUsersWithDays(
            appUser?.id,
            activeUserTeam.appUsers,
            createDatesInterval(
              activeDefaultView?.label === DefaultView.week ? 7 : 14,
              defaultDatesInterval[0]?.date
            ),
            upcomingOccurrences
          )
        );
      }

      const teamsWithOccurrences = buildTeamsWithOccurrences(
        teams,
        occurrences
      );

      setTeams(
        !!appUser?.teamId
          ? sortWithUsersEntityFirst(appUser?.teamId, teamsWithOccurrences)
          : []
      );

      const todaysInterval = createDatesInterval(1, new Date());

      const teamsWithTodaysOccurrences = buildTeamsWithOccurrences(
        teams,
        todaysOccurrences,
        todaysInterval // we just need today
      );

      // get office today users
      getOfficeTodayUsers(teamsWithTodaysOccurrences);

      setIsFetchingTeams(false);
    }
  };

  // fetch Teams and Occurrences via Department id
  const fetchTeams = async () => {
    if (appUser && department) {
      setIsFetchingTeams(true);

      const teams = department.teams
        .map((team: Team) => {
          return {
            ...team,
            appUsers: team.appUsers.map((user: AppUserViewModel) => {
              return {
                ...user,
                name: user.name.replace(/[.]/g, " ").replace(/^(ext)/gi, ""),
                managerOf: team.appUsers
                  .filter((u: AppUserViewModel) => u.managerId === user.id)
                  .map((us: AppUserViewModel) => us.id),
              };
            }),
          };
        })
        .map((team: Team) => ({
          ...team,
          interval: {
            fromDate: defaultDatesInterval[0]?.date,
            toDate: defaultDatesInterval[defaultDatesInterval.length - 1]?.date,
          },
        }));
      await fetchOccurrencesForTeams(teams);
    }
  };

  useEffect(() => {
    if (appUser && appUser.appUserSettings?.defaultView) {
      const today = new Date().setHours(0, 0, 0, 0);

      const usersDashboardView = Object.keys(DefaultView).map(
        (defaultView) => ({
          label: DefaultView[defaultView as DefaultView],
          isActive:
            DefaultView[defaultView as DefaultView] ===
            appUser.appUserSettings.defaultView,
        })
      );

      setDefaultView(usersDashboardView);

      setDefaultDatesInterval(
        createDatesInterval(
          // here probably we can default to 14, so we fetch 2 weeks but only show them conditionally
          appUser.appUserSettings.defaultView === DefaultView.week ? 7 : 14,
          new Date(startOfWeek(today, { weekStartsOn: 1 }).setHours(0, 0, 0, 0))
        )
      );

      setIsLoadingAppUser(false);
    }
  }, [appUser]);

  useEffect(() => {
    if (department.id && defaultDatesInterval.length > 0) {
      // fetch dept data
      fetchTeams();
    }
    // }, [defaultDatesInterval, department, avatars.length > 0]);
  }, [defaultDatesInterval, department]);

  useEffect(() => {
    if (
      appUser?.appUserSettings?.pinnedUsers &&
      allUsers &&
      defaultDatesInterval.length > 0
    ) {
      // build pinned users team
      getPinnedUsersTeam({
        ...pinnedUsersTeam,
        interval: {
          fromDate: defaultDatesInterval[0]?.date,
          toDate: defaultDatesInterval[defaultDatesInterval.length - 1]?.date,
        },
      });
    }
  }, [appUser?.appUserSettings?.pinnedUsers, allUsers, defaultDatesInterval]);

  useEffect(() => {
    const today = new Date().setHours(0, 0, 0, 0);
    const activeDefaultView = defaultView?.find(
      (defaultView) => defaultView.isActive
    );

    if (activeDefaultView) {
      setDefaultDatesInterval(
        createDatesInterval(
          activeDefaultView.label === DefaultView.week ? 7 : 14,
          new Date(startOfWeek(today, { weekStartsOn: 1 }).setHours(0, 0, 0, 0))
        )
      );

      const endOfCurrentWeek = new Date(
        endOfWeek(today, { weekStartsOn: 1 }).setHours(0, 0, 0, 0)
      );

      const endOfNextWeek = new Date(
        endOfWeek(add(new Date(today), { weeks: 1 }).setHours(0, 0, 0, 0), {
          weekStartsOn: 1,
        }).setHours(0, 0, 0, 0)
      );

      add(new Date(today), { weeks: 1 }).setHours(0, 0, 0, 0);

      const updatedTeams = teams.map((team) => ({
        ...team,
        interval: {
          fromDate: new Date(
            startOfWeek(today, { weekStartsOn: 1 }).setHours(0, 0, 0, 0)
          ),
          toDate:
            activeDefaultView.label === DefaultView.week
              ? endOfCurrentWeek
              : endOfNextWeek,
        },
      }));

      setTeams(updatedTeams);

      setPinnedUsersTeam({
        ...pinnedUsersTeam,
        interval: {
          fromDate: new Date(
            startOfWeek(today, { weekStartsOn: 1 }).setHours(0, 0, 0, 0)
          ),
          toDate:
            activeDefaultView.label === DefaultView.week
              ? endOfCurrentWeek
              : endOfNextWeek,
        },
      });
    }
    setIsChangingTimeSpan(false);
  }, [defaultView]);

  // Re-render after successfully updating existing occurrences to ensure new occurrences are visible immediately upon closing the modal  useEffect(() => {}, [selectedDateOccurrence]);

  const onChangeDashboardView = (newActiveTimeSpanLabel: DefaultView) => {
    setIsChangingTimeSpan(true);

    const newTimeSpans = Object.keys(DefaultView).map((defaultViewKey) => {
      const label = defaultViewKey as DefaultView;
      const isActive = label === newActiveTimeSpanLabel;

      // check if the calendar tab was selected & opens popup
      if (label === "calendar" && isActive) {
        setIsCalendarOpen(true);
      }

      // return the object with label and isActive
      return {
        label,
        isActive,
      };
    });

    // if user clicks on a tab again, it will close the calendar popup
    if (isCalendarOpen) {
      setIsCalendarOpen(false);
    }

    setDefaultView(newTimeSpans);
    setIsChangingTimeSpan(false);
  };

  const getOfficeTodayUsers = (teams: TeamViewModel[]) => {
    if (appUser) {
      const isTodaysOccurrence = (occurrenceDate: string) =>
        occurrenceDate ===
        dateFormatter(new Date().toISOString().split("T")[0]);

      const hasOffice = (dailyOccurrences: OccurrenceViewModel[]) =>
        dailyOccurrences.some(
          (occ) =>
            occ.type === OccurrenceType.brunel ||
            occ.type === OccurrenceType.londonbrunel.replace(/\s/g, "") ||
            occ.type === OccurrenceType.singapore ||
            occ.type === OccurrenceType.newyork.replace(/\s/g, "")
        );

      const officeEvents = ["londonbrunel", "brunel", "newyork", "singapore"];

      const usersInOffice = teams
        ?.find((team) => team.id === appUser.teamId)
        ?.appUsers.flatMap((user) =>
          user.days
            .filter(
              (day) =>
                isTodaysOccurrence(day.date) && hasOffice(day.occurrences)
            )
            .flatMap((day) =>
              day.occurrences
                .filter((occ) => officeEvents.includes(occ.type as string))
                .map((officeInstance) => ({
                  ...user,
                  officeToday: officeInstance.type,
                }))
            )
        );

      const londonbrunel = usersInOffice?.filter(
        (user) =>
          (user.officeToday as string) === "londonbrunel" ||
          (user.officeToday as string) === "brunel"
      );
      const singapore = usersInOffice?.filter(
        (user) => (user.officeToday as string) === "singapore"
      );
      const newyork = usersInOffice?.filter(
        (user) => (user.officeToday as string) === "newyork"
      );

      setTodayInOfficeUsers({
        londonbrunel: sortWithUsersEntityFirst(appUser.id, londonbrunel || []),
        newyork: sortWithUsersEntityFirst(appUser.id, newyork || []),
        singapore: sortWithUsersEntityFirst(appUser.id, singapore || []),
      });
    }
  };

  const getUpcomingOccurrences = (appUsers: AppUserViewModel[]) => {
    setIsLoadingUpcomingOccurrences(true);
    return appUsers.map((user) => {
      if (user.id === appUser?.id) {
        const upcomingOccurrences = user.days.filter(
          (day) =>
            day.occurrences.length > 1 || day.occurrences[0].type !== null
        );
        if (upcomingOccurrences.length > 0) {
          setUpcomingOccurrences(upcomingOccurrences);
          setIsLoadingUpcomingOccurrences(false);
        } else {
          setUpcomingOccurrences([]);
          setIsLoadingUpcomingOccurrences(false);
        }
      }
      return [];
      setIsLoadingUpcomingOccurrences(false);
    });
  };

  const onChangeTimeInterval = async (
    teamId: string,
    newStartDate: Date,
    newToDate: Date
  ) => {
    setUpdatingTeamId(teamId);

    if (teamId === "pinned") {
      const updatedPinnedUsersTeam = {
        ...pinnedUsersTeam,
        interval: { fromDate: newStartDate, toDate: newToDate },
      };

      await getPinnedUsersTeam(updatedPinnedUsersTeam);
    } else {
      const updatedTeams = teams.map((team) => {
        if (team.id === teamId) {
          return {
            ...team,
            interval: { fromDate: newStartDate, toDate: newToDate },
          };
        }
        return team;
      });

      await fetchOccurrencesForTeams(updatedTeams);
    }

    setUpdatingTeamId("");
  };

  const onSelectDate = (
    date: string,
    occurrences: OccurrenceViewModel[],
    targetUser: AppUserViewModel
  ) => {
    setSelectedDate(date);
    setTargetUser(targetUser);
    setSelectedDateOccurrence(occurrences);
  };

  const onSuccess = async () => {
    if (!appUser) return;

    const targetUserTeam = teams.find((team) => team.id === targetUser?.teamId);
    const activeDefaultView = defaultView?.find(
      (defaultView) => defaultView.isActive
    );

    if (targetUser && targetUserTeam) {
      const refreshedOccurrences =
        await occurrenceService.getOccurrencesByUserId({
          userId: targetUser?.id,
          from_date: targetUserTeam?.interval.fromDate,
          to_date: targetUserTeam?.interval.toDate,
        });

      const newTeams = teams.map((team) =>
        team.id === targetUserTeam.id
          ? {
              ...team,
              appUsers: team.appUsers.map((appUser) =>
                appUser.id === targetUser.id
                  ? {
                      ...appUser,
                      days: generateDays(
                        createDatesInterval(
                          activeDefaultView?.label === DefaultView.week
                            ? 7
                            : 14,
                          team.interval.fromDate
                        ),
                        refreshedOccurrences
                      ),
                    }
                  : appUser
              ),
            }
          : team
      );

      fetchOccurrencesForTeams(newTeams);

      // refresh target user occurrences
      const appUsers = pinnedUsersTeam.appUsers.map((appUser) =>
        appUser.id === targetUser.id
          ? { ...appUser, occurrences: refreshedOccurrences }
          : appUser
      );

      const newPinnedUsersTeam = {
        ...pinnedUsersTeam,
        appUsers: sortWithUsersEntityFirst(appUser.id, appUsers),
      };

      getPinnedUsersTeam(newPinnedUsersTeam);

      setSelectedDate("");
    }
  };

  const onFavouritesClick = async (newFavUser: AppUserViewModel) => {
    let favouriteUsersCopy = [...pinnedUsers];

    const favUserIndex = favouriteUsersCopy?.findIndex(
      (favUser) => favUser.id === newFavUser.id
    );

    setIsPinningUserId(newFavUser.id);

    if (favUserIndex > -1) {
      // we're removing a user
      favouriteUsersCopy.splice(favUserIndex, 1);
      updateUserSettings({
        pinnedUsers: favouriteUsersCopy.map((user) => user.id),
      });
      return;
    } else {
      favouriteUsersCopy = [...pinnedUsers, newFavUser];
      updateUserSettings({
        pinnedUsers: favouriteUsersCopy.map((user) => user.id),
      });
    }
  };

  if (!appUser?.id ?? !allUsers) {
    return <LoadingScreen />;
  }

  // function to handle calendar date selection
  const calendarDateSelection = (selectedDateISO: string | string[]) => {
    // | string[] is used here because it Grommet's defaultValue

    // to handle the string format from Grommet
    const selectedSingleDate = Array.isArray(selectedDateISO)
      ? selectedDateISO[0]
      : selectedDateISO;

    // convert ISO string to new Date object
    const selectedDate = new Date(selectedSingleDate);

    // define defaultView
    const activeDefaultView = defaultView?.find(
      (defaultView) => defaultView.isActive
    );

    const newFromDate = new Date(
      startOfWeek(selectedDate, { weekStartsOn: 1 }).setHours(0, 0, 0, 0)
    );

    // for 2 weeks view
    const newToDate =
      activeDefaultView?.label === DefaultView.week
        ? new Date(
            endOfWeek(selectedDate, { weekStartsOn: 1 }).setHours(0, 0, 0, 0)
          )
        : new Date(
            endOfWeek(
              add(new Date(selectedDate), { weeks: 1 }).setHours(0, 0, 0, 0),
              {
                weekStartsOn: 1,
              }
            ).setHours(0, 0, 0, 0)
          );

    // set the defaultDatesInterval for icons re-render
    setDefaultDatesInterval(
      createDatesInterval(
        activeDefaultView?.label === DefaultView.week ? 7 : 14,
        newFromDate
      )
    );

    // add the interval properties to the team object
    const updatedTeams = teams.map((team) => ({
      ...team,
      interval: {
        fromDate: newFromDate,
        toDate: newToDate,
      },
    }));

    setTeams(updatedTeams);

    // closes calendar on selection
    if (isCalendarOpen) {
      setIsCalendarOpen(false);
    }
  };

  // callback function for notifications
  const triggerToast = (message: string) => {
    setShowToast({ show: true, message });
  };

  return (
    <>
      <Container>
        {isDesktop ? (
          <PageHeader>
            <Box style={{ flexShrink: 0 }}>
              <AvatarHeader appUser={appUser} />
            </Box>
            <IconLegend />
          </PageHeader>
        ) : null}

        <PageContent>
          <TeamsColumn style={{ overflow: isDesktop ? "unset" : "auto" }}>
            <DashboardOptions
              style={{ border: isDesktop ? "1px solid #cccccc" : "unset" }}
            >
              <Box
                width={isDesktop ? "300px" : "unset"}
                style={{ flexShrink: 0 }}
              >
                <Box direction="row" align="start">
                  <Search style={{ padding: "10px 10px" }} />
                  <StyledTextInput
                    type="search"
                    placeholder="Search name"
                    name="search"
                    value={filterValue}
                    onChange={(occurrence) =>
                      setFilterValue(occurrence.target.value)
                    }
                  />
                </Box>
              </Box>

              <div style={{ position: "relative" }}>
                {isDesktop ? (
                  <TabSwitcher
                    isChangingTimeSpan={isChangingTimeSpan}
                    tabs={defaultView}
                    onClick={onChangeDashboardView}
                  />
                ) : null}

                {/*if calendar state is open then display underneath */}
                {isCalendarOpen ? (
                  <div
                    className="calendar-popup"
                    style={{
                      position: "absolute",
                      top: "130%",
                      left: "0",
                      backgroundColor: "#ffffff",
                      zIndex: 1000,
                    }}
                  >
                    <DateInput
                      format="dd/mm/yyyy"
                      value={new Date().toISOString()}
                      onChange={({ value }) => {
                        calendarDateSelection(value);
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </DashboardOptions>
            {teams.length === 0 && isFetchingTeams ? (
              <TeamColumnPlaceholder />
            ) : (
              <>
                {isLoadingAppUser ? (
                  <TeamColumnPlaceholder single />
                ) : !isLoadingAppUser && pinnedUsersTeam.appUsers.length > 0 ? (
                  <PinnedUsersTeamCard
                    allUsers={allUsers}
                    filterValue={filterValue}
                    defaultView={defaultView}
                    appUser={appUser}
                    pinnedUsers={pinnedUsers}
                    onFavouritesClick={onFavouritesClick}
                    defaultDatesInterval={defaultDatesInterval}
                    onSelectDate={onSelectDate}
                    pinnedUsersTeam={pinnedUsersTeam}
                    onChangeTimeInterval={onChangeTimeInterval}
                    isLoadingPinnedUsersData={isLoadingPinnedUsersData}
                    isMutatingPinnedUsers={isMutatingPinnedUsers}
                    isRefetchingPinnedUsersData={isLoadingPinnedUsersData}
                  />
                ) : null}
                {teams.map((team, i) => (
                  <React.Fragment key={team.id}>
                    <TeamCard
                      allUsers={allUsers}
                      showPinOnHover
                      filterValue={filterValue}
                      activeTimeSpan={
                        defaultView?.filter(
                          (defaultView) => defaultView.isActive
                        )[0].label
                      }
                      isPinningUserId={isPinningUserId}
                      appUserRole={appUser.role}
                      favouriteAppUsers={pinnedUsers}
                      onFavouritesClick={onFavouritesClick}
                      onSelectDate={onSelectDate}
                      showResetButton={
                        defaultDatesInterval[0]?.date.setHours(0, 0, 0, 0) !==
                        team.interval.fromDate.setHours(0, 0, 0, 0)
                      }
                      onResetToCurrentTimeSpan={() =>
                        onChangeTimeInterval(
                          team.id,
                          defaultDatesInterval[0]?.date,
                          defaultDatesInterval[defaultDatesInterval.length - 1]
                            ?.date
                        )
                      }
                      appUserId={appUser?.id}
                      isLoading={updatingTeamId === team.id}
                      team={team}
                      onChangeTimeInterval={onChangeTimeInterval}
                    />
                    {i !== teams?.length - 1 ? <HorizontalSeparator /> : null}
                  </React.Fragment>
                ))}
              </>
            )}
          </TeamsColumn>
          {isMinWidth1000 ? (
            <>
              <VerticalSeparator />
              {teams.length === 0 ? (
                <UpcomingEventsPlaceholder />
              ) : (
                <Box direction="column">
                  <OfficeTodaySummaryCard
                    team={
                      teams.find((team) => team.id === appUser.teamId) ||
                      ({} as TeamViewModel)
                    }
                    appUser={appUser}
                    todayInOfficeUsers={todayInOfficeUsers}
                  />
                  <UpcomingEventsSummaryCard
                    fromDate={defaultDatesInterval[0]?.date}
                    toDate={
                      defaultDatesInterval[defaultDatesInterval.length - 1]
                        ?.date
                    }
                    isLoadingUpcomingOccurrences={isLoadingUpcomingOccurrences}
                    upcomingOccurrences={upcomingOccurrences}
                  />
                </Box>
              )}
            </>
          ) : null}
        </PageContent>
      </Container>
      {!!selectedDate && !!targetUser && (
        <EventModal
          appUser={appUser}
          targetUser={targetUser}
          date={selectedDate}
          selectedDateOccurrence={selectedDateOccurrence}
          onCloseModal={() => setSelectedDate("")}
          onSuccess={onSuccess}
          triggerToast={triggerToast}
        />
      )}
      {showToast?.show ? (
        <Notification
          icon={<Info />}
          toast={{
            autoClose: true,
            position: "top",
          }}
          time={3000}
          status="info"
          title={showToast.message}
          onClose={() => setShowToast({ show: false, message: "" })}
        />
      ) : null}
    </>
  );
};
