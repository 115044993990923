import {
  Box,
  Header as GrommetHeader,
  Layer,
  Nav,
  Notification,
  Text,
} from "grommet";
import {
  Group,
  Info,
  Notification as NotificationIcon,
  Projects,
  User,
} from "grommet-icons";
import { PremierLeagueLogoWhite } from "../assets/PremierLeagueLogoWhite";
import React, { useState } from "react";
import background from "../assets/heading-bg-desktop 1.svg";
import { colors } from "../assets/theme";
import styled from "styled-components";
import { useComponentVisible } from "../hooks/useComponentVisible";
import { Avatar } from "./Avatar";
import { AppUserViewModel, UpdateSettingsPayload } from "../types";
import { SideBarLink } from "./SideBarLink";
import { Link, useLocation } from "react-router-dom";
import { Settings } from "./settings/Settings";
import useMediaQuery from "../hooks/ueMediaQuery";
import { Hamburger } from "./Hamburger";
import { MobileCloseModalButton } from "./buttons/MobileCloseModalButton";
import { FAQ } from "./FAQ";
import { useMutateAppUserSettings } from "../hooks/useMutateAppUserSettings";
import { NotificationsSettings } from "./settings/NotificationsSettings";

const MAILTO =
  "mailto:servicedesk@premierleague.com?cc=Elias.Chrysostomou@premierleague.com&subject=Where%20Am%20I%20Support";
const Header = styled.header<{ isDesktop: boolean }>`
  display: flex;
  position: sticky;
  align-items: center;
  justify-content: center;
  background-color: ${() => colors.plWhite};
  z-index: 3;
  padding: ${({ isDesktop }) => (isDesktop ? "0 12px;" : "5px 12px;")}
  top: 0;
  border-bottom: ${() => `1px solid ${colors.plGrey02}`};
`;

const HeaderContents = styled.div<{ isDesktop: boolean }>`
  display: flex;
  justify-content: space-between;
  width: ${({ isDesktop }) => (isDesktop ? "1200px;" : "100%;")};
`;

const PLWave = styled.img<{ isDesktop?: boolean }>`
  height: ${({ isDesktop }) => (isDesktop ? "60px" : "48px")};
  position: absolute;
  top: 0;
  right: 0;
  z-index: -5;
  opacity: 0.5;
`;

const AppTitleContainer = styled(Link)`
  display: flex;
  align-items: center;
  z-index: 1;
  padding-inline: 0 30px;
  text-decoration: none;
`;

const AppTitle = styled.span`
  position: relative;
  margin-left: 24px;
  font-weight: bolder;
  color: ${() => colors.plDarkPurple};
  font-size: 22px;
  line-height: 28px;
  white-space: pre;
`;

const UserContextBox = styled.div`
  position: absolute;
  right: -40px;
  top: 55px;
  z-index: 2;
  background-color: ${() => colors.plWhite};
  border: ${() => `1px solid ${colors.plDarkPurple06}`};
  border-radius: 5px;
`;

const UserContextAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  text-align: center;
  height: 35px;
  padding: 10px 0;
  cursor: pointer;
  :hover {
    background-color: ${() => colors.hoveringGrey};
  }
`;

export const AppBar = ({
  appUser,
  onHamburgerClick,
  onLogOut,
}: {
  appUser?: AppUserViewModel;
  onHamburgerClick: () => void;
  onLogOut: () => void;
}) => {
  const isDesktop = useMediaQuery("(min-width:650px)");
  const minWidth1065 = useMediaQuery("(min-width:1065px)");

  const location = useLocation();
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const [showFaqs, setShowFaqs] = useState<boolean>(false);

  const [showToast, setShowToast] = useState<{
    show: boolean;
    message: string;
  }>();

  const { mutateAppUserSettings, isMutatingAppUserSettings } =
    useMutateAppUserSettings();

  const {
    ref: contextMenuRef,
    isComponentVisible: isContextMenuVisible,
    setIsComponentVisible: setIsContextMenuVisible,
  } = useComponentVisible(false);

  const onUpdateSettings = (payload: Partial<UpdateSettingsPayload>) => {
    setShowToast({ show: false, message: "" });
    if (appUser?.appUserSettings) {
      const newSettings = {
        ...appUser.appUserSettings,
        ...payload,
      };
      mutateAppUserSettings(newSettings).then((res) => {
        if (res.status === 201) {
          setShowToast({
            show: true,
            message: "Settings saved.",
          });
        } else {
          setShowToast({
            show: true,
            message: "Ooops! " + res,
          });
        }
      });
    }
  };

  return (
    <Header isDesktop={isDesktop} data-testid="app-bar">
      <HeaderContents isDesktop={isDesktop}>
        <Box direction="row" style={{ gap: "2em" }}>
          <AppTitleContainer
            to="/"
            style={{
              textDecoration: "none",
            }}
            state={{ appUser: appUser }}
          >
            <PremierLeagueLogoWhite />
            <AppTitle>Where Am I</AppTitle>
          </AppTitleContainer>

          {isDesktop ? (
            <Nav
              gap="large"
              direction="row"
              alignSelf="end"
              style={{ zIndex: 1 }}
            >
              <SideBarLink
                appUser={appUser}
                onClick={() => {
                  setIsContextMenuVisible(false);
                }}
                isActive={location.pathname === "/dashboard"}
                navigateTo="/dashboard"
                label="Dashboard"
                icon={
                  <Projects
                    color={
                      location.pathname === "/dashboard"
                        ? colors.plMagenta06
                        : colors.plDarkPurple06
                    }
                  />
                }
              />
              <SideBarLink
                appUser={appUser}
                onClick={() => {
                  setIsContextMenuVisible(false);
                }}
                isActive={location.pathname === "/calendar"}
                navigateTo="/calendar"
                label="Calendar"
                icon={
                  <User
                    color={
                      location.pathname === "/calendar"
                        ? colors.plMagenta06
                        : colors.plDarkPurple06
                    }
                  />
                }
              />
              <SideBarLink
                appUser={appUser}
                onClick={() => {
                  setIsContextMenuVisible(false);
                }}
                isActive={location.pathname === "/teams"}
                navigateTo="/teams"
                label="Teams"
                icon={
                  <Group
                    color={
                      location.pathname === "/teams"
                        ? colors.plMagenta06
                        : colors.plDarkPurple06
                    }
                  />
                }
              />
            </Nav>
          ) : null}
        </Box>
        <Box direction="row" align="center">
          <Box
            direction="row"
            align="center"
            onClick={() => {
              setShowNotifications(true);
            }}
            margin={{ right: "25px" }}
            background={colors.plWhite}
            pad="5px"
            style={{
              borderRadius: "5px",
              border: `2px solid ${colors.plPurple}`,
            }}
          >
            {minWidth1065 ? (
              <span
                style={{
                  marginRight: "15px",
                  fontWeight: "bold",
                  color: colors.plPurple,
                }}
              >
                Notifications
              </span>
            ) : null}
            <NotificationIcon
              style={{ flexShrink: 0 }}
              color={colors.plPurple}
            />
          </Box>
          {isDesktop ? (
            <div style={{ position: "relative" }}>
              <Avatar
                isClickable
                size="medium"
                onClick={() => setIsContextMenuVisible(!isContextMenuVisible)}
                style={{ zIndex: 2, cursor: "pointer" }}
                appUser={appUser}
              />
              {isContextMenuVisible ? (
                <UserContextBox ref={contextMenuRef}>
                  <UserContextAction>
                    <Text
                      onClick={() => {
                        setShowSettings(true);
                        setIsContextMenuVisible(false);
                      }}
                      weight="bold"
                      color={colors.plPurple}
                    >
                      Settings
                    </Text>
                  </UserContextAction>
                  <UserContextAction>
                    <Text
                      onClick={(e) => {
                        e.preventDefault();
                        setShowFaqs(true);
                      }}
                      weight="bold"
                      color={colors.plPurple}
                    >
                      FAQ
                    </Text>
                  </UserContextAction>
                  <UserContextAction>
                    <Text
                      onClick={(e) => {
                        window.location.href = MAILTO;
                        e.preventDefault();
                      }}
                      weight="bold"
                      color={colors.plPurple}
                    >
                      Support
                    </Text>
                  </UserContextAction>
                  <UserContextAction>
                    <Text
                      onClick={onLogOut}
                      weight="bold"
                      color={colors.plPurple}
                    >
                      Logout
                    </Text>
                  </UserContextAction>
                </UserContextBox>
              ) : null}
            </div>
          ) : (
            <Hamburger onClick={onHamburgerClick} />
          )}
        </Box>
        {showSettings && appUser?.appUserSettings ? (
          <Settings
            settings={appUser.appUserSettings}
            onClose={() => setShowSettings(false)}
          />
        ) : null}
        {showNotifications && appUser?.appUserSettings ? (
          <Layer
            onEsc={() => setShowNotifications(false)}
            onClickOutside={() => setShowNotifications(false)}
          >
            <MobileCloseModalButton
              topRight
              onClick={() => setShowNotifications(false)}
            />
            <Box
              pad={{ vertical: "medium", horizontal: "large" }}
              justify="start"
              width="400px"
            >
              <GrommetHeader
                margin={{ top: "20px", bottom: "50px" }}
                alignSelf="start"
                style={{ color: colors.plPurple, fontSize: "20px" }}
              >
                Notifications
              </GrommetHeader>
              <NotificationsSettings
                isLoading={isMutatingAppUserSettings}
                onUpdate={onUpdateSettings}
                settings={appUser.appUserSettings}
              />
            </Box>
          </Layer>
        ) : null}
        {showFaqs ? <FAQ onClose={() => setShowFaqs(false)} /> : null}
      </HeaderContents>

      {showToast?.show ? (
        <Notification
          icon={<Info />}
          toast={{
            autoClose: true,
            position: "top",
          }}
          time={3000}
          status="info"
          title={showToast.message}
          onClose={() => setShowToast({ show: false, message: "" })}
        />
      ) : null}
      {isDesktop ? <PLWave src={background} isDesktop={isDesktop} /> : null}
    </Header>
  );
};
