import { CreateEventPayload } from "../types";
import axios from "axios";

export default class EventService {
  createEvent = async (payload: CreateEventPayload) => {
    return axios
      .post(`/events`, payload)
      .then((data) => data)
      .catch((err) => {
        console.error(err);
        throw new Error(err.response.data.error);
      });
  };
}
