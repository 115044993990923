import styled from "styled-components";
import { colors } from "../../assets/theme";

export const SaveButton = styled.button`
  background-color: ${({ disabled }) =>
    !disabled ? colors.plPurple01 : colors.plStone02};
  border-radius: 4px;
  min-height: fit-content;
  color: ${({ disabled }) => (!disabled ? colors.plPurple : colors.plGrey02)};
  border: none;
  font-family: "Premier League";
  padding: 15px;
  font-weight: bold;
  cursor: pointer;
  min-width: 90%;
`;
