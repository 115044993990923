import { Box, Header, Layer, Notification } from "grommet";
import { VerticalSeparator } from "../VerticalSeparator";
import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../assets/theme";
import { SettingsSections } from "../../enums";
import { capitalizeFirstLetter } from "../../utils";
import useMediaQuery from "../../hooks/ueMediaQuery";
import { MobileCloseModalButton } from "../buttons/MobileCloseModalButton";
import { AppUserSettings, UpdateSettingsPayload } from "../../types";
import { Info } from "grommet-icons";
import { useMutateAppUserSettings } from "../../hooks/useMutateAppUserSettings";
import { DashboardSettings } from "./DashboardSettings";
import { NotificationsSettings } from "./NotificationsSettings";
import { OfficeSettings } from "./OfficeSettings";
import { DelegatesSettings } from "./DelegatesSettings";

const StyledLayer = styled(Layer)<{ isDesktop?: boolean }>`
  width: ${({ isDesktop }) => (isDesktop ? "650px" : null)};
  min-height: 450px;
  max-height: 450px;
  height: ${({ isDesktop }) => (isDesktop ? "unset" : "450px")};
`;

export const Settings = ({
  onClose,
  settings,
}: {
  onClose: () => void;
  settings: AppUserSettings;
}) => {
  const isDesktop = useMediaQuery("(min-width:650px)");
  const [showToast, setShowToast] = useState<{
    show: boolean;
    message: string;
  }>();

  const [activeSection, setActiveSection] = useState<string>(
    SettingsSections.notifications as string
  );

  const { mutateAppUserSettings, isMutatingAppUserSettings } =
    useMutateAppUserSettings();

  const onUpdateSettings = (payload: Partial<UpdateSettingsPayload>) => {
    setShowToast({ show: false, message: "" });
    if (settings) {
      const newSettings = {
        ...settings,
        ...payload,
      };
      mutateAppUserSettings(newSettings).then((res) => {
        if (res.status === 201) {
          setShowToast({
            show: true,
            message: "Settings saved.",
          });
        } else {
          setShowToast({
            show: true,
            message: "Ooops! " + res,
          });
        }
      });
    }
  };

  const getSettings = () => {
    switch (true) {
      case activeSection === SettingsSections.notifications:
        return (
          <NotificationsSettings
            isLoading={isMutatingAppUserSettings}
            onUpdate={(payload) => {
              setShowToast({ show: false, message: "" });
              onUpdateSettings(payload);
            }}
            settings={settings}
          />
        );
      case activeSection === SettingsSections.dashboard:
        return (
          <DashboardSettings
            onUpdate={(payload) => {
              setShowToast({ show: false, message: "" });
              onUpdateSettings(payload);
            }}
            settings={settings}
          />
        );
      case activeSection === SettingsSections.office:
        return (
          <OfficeSettings
            onUpdate={(payload) => {
              setShowToast({ show: false, message: "" });
              onUpdateSettings(payload);
            }}
            settings={settings}
          />
        );
      case activeSection === SettingsSections.delegates:
        return <DelegatesSettings onClose={onClose} />;
      default:
        return;
    }
  };

  return (
    <StyledLayer
      onEsc={onClose}
      onClickOutside={onClose}
      isDesktop={isDesktop}
      data-testid={"settings"}
    >
      <MobileCloseModalButton topRight onClick={onClose} />
      <Header
        margin="20px"
        alignSelf="start"
        style={{ color: colors.plPurple, fontSize: "22px" }}
      >
        Settings
      </Header>
      <Box pad="24px" direction="row" style={{ minHeight: "inherit" }}>
        <Box direction="column" width="180px" pad="10px 0" align="start">
          {Object.keys(SettingsSections).map((settingSection) => (
            <Box
              style={{
                fontWeight: "bold",
                textAlign: "left",
                borderRadius: "5px",
                color: isMutatingAppUserSettings
                  ? colors.plDarkGrey
                  : colors.plPurple,
                width: "inherit",
              }}
              key={settingSection}
              onClick={() =>
                !isMutatingAppUserSettings
                  ? setActiveSection(settingSection)
                  : null
              }
              pad="5px 10px"
              background={
                isMutatingAppUserSettings
                  ? colors.plStone02
                  : activeSection === settingSection
                  ? colors.plPurple01
                  : "transparent"
              }
              margin={{ bottom: "5px" }}
            >
              {capitalizeFirstLetter(settingSection)}
            </Box>
          ))}
        </Box>
        <VerticalSeparator margin={isDesktop ? "24px" : "10px"} />
        <Box
          direction="column"
          width={isDesktop ? "100%" : "80%"}
          pad="10px 0"
          style={{ maxHeight: "100%" }}
        >
          {getSettings()}
        </Box>
      </Box>
      {showToast?.show ? (
        <Notification
          icon={<Info />}
          toast={{
            autoClose: true,
            position: "top",
          }}
          time={3000}
          status="info"
          title={showToast.message}
          onClose={() => setShowToast({ show: false, message: "" })}
        />
      ) : null}
    </StyledLayer>
  );
};
