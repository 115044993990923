import { Box, Header, Layer, Notification } from "grommet";
import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../assets/theme";

import useMediaQuery from "../hooks/ueMediaQuery";
import { MobileCloseModalButton } from "./buttons/MobileCloseModalButton";
import { Info } from "grommet-icons";
import { CancelButton } from "./buttons/CancelButton";
import { ButtonComp } from "./buttons/Button";

const StyledLayer = styled(Layer)<{ isDesktop?: boolean }>`
  padding: 0.5em;
  width: ${({ isDesktop }) => (isDesktop ? "650px" : null)};
  min-height: 1em;
  max-height: 25em;
  height: ${({ isDesktop }) => (isDesktop ? "unset" : "450px")};
`;

export const ExistingOccModal = ({
  onCloseOverrideOccModal,
  onCloseEventModal,
  onSave,
  existingOccurrences,
}: {
  onCloseOverrideOccModal: () => void;
  onCloseEventModal: () => void;
  onSave: () => void;
  existingOccurrences?: string[];
}) => {
  const isDesktop = useMediaQuery("(min-width:650px)");
  const [showToast, setShowToast] = useState<{
    show: boolean;
    message: string;
  }>();

  async function handleOnsave() {
    await onSave();
    // onCloseEventModal();
  }

  return (
    <StyledLayer
      onEsc={onCloseOverrideOccModal}
      // onClickOutside={onCloseOverrideOccModal}
      isDesktop={isDesktop}
      data-testid={"Override existing occurrences"}
    >
      <MobileCloseModalButton topRight onClick={onCloseOverrideOccModal} />
      <Header
        margin="20px"
        alignSelf="start"
        style={{ color: colors.plPurple, fontSize: "22px" }}
      >
        Overwrite Existing Data?
      </Header>
      <Box pad="10px 24px" direction="column" style={{ minHeight: "inherit" }}>
        <Box direction="column" pad="10px 0" align="start">
          We found existing occurrences in this time period. If you continue,
          they will be replaced with the new occurrences.
        </Box>
        <p>Would you like to proceed?</p>

        <Box direction="row" align="center" gap="5px">
          <ButtonComp onClick={handleOnsave}>Yes</ButtonComp>
          <CancelButton onClick={onCloseOverrideOccModal}>Cancel</CancelButton>
        </Box>
      </Box>

      {showToast?.show ? (
        <Notification
          icon={<Info />}
          toast={{
            autoClose: true,
            position: "top",
          }}
          time={3000}
          status="info"
          title={showToast.message}
          onClose={() => setShowToast({ show: false, message: "" })}
        />
      ) : null}
    </StyledLayer>
  );
};
