import { Box, Nav, Sidebar, Text } from "grommet";
import { Projects, User, Group, Tasks } from "grommet-icons";
import React, { ReactElement, useState } from "react";
import { SideBarLink } from "./SideBarLink";
import { APPBAR_HEIGHT } from "../App";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { colors } from "../assets/theme";
import { AppUserViewModel } from "../types";
import { MobileCloseModalButton } from "./buttons/MobileCloseModalButton";
import { Settings } from "./settings/Settings";
import { Logout } from "grommet-icons";

const UserContextAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 35px;
  padding: 10px 0;
  cursor: pointer;
  :hover {
    background-color: ${() => colors.hoveringGrey};
  }
`;

type SideBarProps = {
  children: ReactElement<any, any>;
  appUser?: AppUserViewModel;
  isOpen: boolean;
  onClose: () => void;
  onLogOut: () => void;
};

const SideBarContainer = styled.div`
  display: flex;
  min-height: ${() => `calc(100vh - ${APPBAR_HEIGHT})`};
  box-sizing: border-box;
`;

export const MobileSidebar = ({
  children,
  appUser,
  isOpen,
  onClose,
  onLogOut,
}: SideBarProps) => {
  const location = useLocation();
  const [showSettings, setShowSettings] = useState<boolean>(false);
  return (
    <SideBarContainer>
      <Sidebar
        data-testid={"sidebar"}
        align="center"
        background={colors.plWhite}
        width="200px"
        border={{ side: "right", color: colors.plGrey02 }}
        flex="grow"
        style={{
          position: "fixed",
          zIndex: 3,
          top: 0,
          right: 0,
          bottom: 0,
          left: "auto",
          width: "200px",
          display: isOpen ? "block" : "none",
          boxShadow: `0px 0 0.4em ${colors.plPurple}`,
        }}
      >
        <MobileCloseModalButton onClick={onClose} />
        <Nav gap="large">
          <SideBarLink
            appUser={appUser}
            onClick={onClose}
            isActive={location.pathname === "/dashboard"}
            navigateTo="/dashboard"
            label="Dashboard"
            icon={
              <Projects
                color={
                  location.pathname === "/dashboard"
                    ? colors.plMagenta06
                    : colors.plDarkPurple06
                }
              />
            }
          />
          <SideBarLink
            appUser={appUser}
            onClick={onClose}
            isActive={location.pathname === "/calendar"}
            navigateTo="/calendar"
            label="Calendar"
            icon={
              <User
                color={
                  location.pathname === "/calendar"
                    ? colors.plMagenta06
                    : colors.plDarkPurple06
                }
              />
            }
          />
          <SideBarLink
            appUser={appUser}
            onClick={onClose}
            isActive={location.pathname === "/teams"}
            navigateTo="/teams"
            label="Teams"
            icon={
              <Group
                color={
                  location.pathname === "/teams"
                    ? colors.plMagenta06
                    : colors.plDarkPurple06
                }
              />
            }
          />
        </Nav>
        <Box
          direction="column"
          width="100%"
          style={{ position: "absolute", bottom: "50px" }}
        >
          <UserContextAction>
            <Tasks color={colors.plPurple} />
            <Text
              margin={{ horizontal: "small" }}
              onClick={() => {
                setShowSettings(true);
                onClose();
              }}
              weight="bold"
              color={colors.plPurple}
            >
              Settings
            </Text>
          </UserContextAction>
          <UserContextAction>
            <Logout color={colors.plPurple} />
            <Text
              onClick={onLogOut}
              weight="bold"
              margin={{ horizontal: "small" }}
              color={colors.plPurple}
            >
              Logout
            </Text>
          </UserContextAction>
        </Box>
        {showSettings && appUser?.appUserSettings ? (
          <Settings
            settings={appUser.appUserSettings}
            onClose={() => setShowSettings(false)}
          />
        ) : null}
      </Sidebar>
      {children}
    </SideBarContainer>
  );
};
